import { styled } from "styled-components";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { API_BASE, TOASTPROPS } from "../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { APIKey } from "../shared/Interfaces";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import { parseDate } from "../shared/Utils";
import 'react-toastify/dist/ReactToastify.css';
import { Skeleton } from "@mui/material";


function APIKeys() {
    const {getAccessTokenSilently} = useAuth0();
    const [keys, setKeys] = useState<APIKey[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [newKey, setNewKey] = useState<string>("");
    const [createdNewKeyName, setCreatedNewKeyName] = useState<string>("");
    const [keyToDelete, setKeyToDelete] = useState<APIKey>();
    const [isOpen, setIsOpen] = useState<boolean>(false); // Dialog state

    const handleCloseDialog = () => {
        setCreatedNewKeyName("");
        setIsOpen(false);
    }

    const handleOpenDialog = () => {
        // Create a random name to the key
        const randomName = "chave_" + Math.random().toString(36).substring(2, 12);
        setNewKey(randomName);
        setIsOpen(true);
    }

    useEffect(() => {
        const fetchkeys = async () => {
            setLoading(true);
            try{
                const url = API_BASE + "/api/keys/list";
                const access_token = await getAccessTokenSilently();
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        Authorization: `Auth ${access_token}`,
                    },
                });
                if (response.ok){
                    const data = await response.json();
                    setKeys(data["api_keys"]);
                }
            } catch (e) {
                toast.error("Erro ao buscar chaves de API.", TOASTPROPS);
            }
            setLoading(false);
        };
        fetchkeys();
    }, []);


    const handleCreateNewKey = async (name: string) => {
        const url = API_BASE + "/api/keys/create";
        const access_token = await getAccessTokenSilently();
    
        try {
            const response = await fetch(`${url}?name=${name}`, {
                method: "POST",
                headers: {
                    Authorization: `Auth ${access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                window.navigator.clipboard.writeText(data["api_key"]["key"]);
                // format key show only 6 first and 6 last characters
                setCreatedNewKeyName(name);
                setKeys([...keys, data["api_key"]]);
                setNewKey(data["api_key"]["key"]);
            } else {
                // Get the response message and add to the message:
                const errorMessage = await response.text();
                const parsedMessage = JSON.parse(errorMessage);
                toast.error(parsedMessage.detail, TOASTPROPS);
            }
        } catch (e) {
            toast.error("Erro ao criar chave de API.", TOASTPROPS);
        }
    }

    const handleCopyNewKey = (key: string) => {
        window.navigator.clipboard.writeText(key);
        toast.success("Chave copiada para a área de transferência.", TOASTPROPS);
    }
    
    const handleConfirmDelete = async () => {
        if (keyToDelete) {
            await handleDeleteKey(keyToDelete);
            setKeyToDelete(undefined);
        }
    }

    const handleDeleteKey = async (key: APIKey) => {
        const url = API_BASE + "/api/keys/delete";
        const access_token= await getAccessTokenSilently();
        try {
            const body_data = {
                "api_key_name": key.name
            };
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Auth ${access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body_data),
            });
            if (response.ok){
                setKeys(keys.filter((k) => k.name != key.name));
                toast.success("Chave de API apagada com sucesso.", TOASTPROPS);
            }
            else {
                toast.error("Erro ao apagar chave de API.", TOASTPROPS);
            }
        } catch (e) {
            toast.error("Erro ao apagar chave de API.", TOASTPROPS);
        }
    }

    return (
        <Container>
            <CreateButton onClick={handleOpenDialog}>Criar Nova Chave</CreateButton>
            <TableContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Chave de API</th>
                            <th>Data de Criação</th>
                            <th>Última Vez Utilizada</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <>
                                {Array.from(new Array(4)).map((_, index) => (
                                    <tr key={index}>
                                        {Array.from(new Array(4)).map((_, index) => (
                                            <td key={index}>
                                                <Skeleton variant="text" width={100} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </>
                        ) : (
                            keys.length !== 0 ? (
                                keys.map((key) => (
                                    <tr key={key.name}>
                                        <td>{key.name}</td>
                                        <td>{key.key}</td>
                                        <td>{key.created_at ? parseDate(key.created_at) : "-"}</td>
                                        <td>{key.last_time_used ? parseDate(key.last_time_used) : "-"}</td>
                                        <ButtonContainer>
                                            <Button onClick={() => setKeyToDelete(key)}>
                                                <StyledDeleteIcon/>
                                            </Button>
                                        </ButtonContainer>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>Nenhuma chave encontrada.</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </TableContainer>
            <StyledModal
                open={isOpen}
                onClose={handleCloseDialog}
                BackdropProps={{onClick: (event) => event.stopPropagation()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer>
                    <TitleContainer>
                        <Title>Criar chave de API</Title>
                        <CloseButton type="button" onClick={handleCloseDialog}>
                            <CloseIcon/>
                        </CloseButton>
                    </TitleContainer>
                    {createdNewKeyName !== "" ?
                        <>
                            <KeyName>Nome: {createdNewKeyName}</KeyName>
                            <Input type="text" value={newKey} disabled />
                            <CreateModalButton onClick={() => handleCopyNewKey(newKey)}>Copiar</CreateModalButton>
                        </>
                        :
                        <>
                            <Desc>Escolha um nome único para sua chave de API</Desc>
                            <Input type="text" value={newKey} onChange={(e) => setNewKey(e.target.value)} />
                            <CreateModalButton onClick={() => handleCreateNewKey(newKey)}>Criar</CreateModalButton>
                        </>
                    }
                </ModalContainer>
            </StyledModal>
            <StyledModal
                open={keyToDelete !== undefined}
                onClose={() => setKeyToDelete(undefined)}
                BackdropProps={{onClick: (event) => event.stopPropagation()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer>
                    <TitleContainer>
                        <Title>Apagar chave de API</Title>
                        <CloseButton type="button" onClick={() => setKeyToDelete(undefined)}>
                            <CloseIcon/>
                        </CloseButton>
                    </TitleContainer>
                    <Desc>Tem certeza que deseja apagar a chave de API <strong>{keyToDelete?.name}</strong>?</Desc>
                    <CreateModalButton onClick={handleConfirmDelete}>Confirmar</CreateModalButton>
                </ModalContainer>
            </StyledModal>
            <ToastContainer/>
        </Container>
    );
}

export default APIKeys

const KeyName = styled.p`
    font-size: 1.4rem;
    color: ${props => props.theme.dark_gray};
    margin: 0px 0px 10px 22px;
    flex-wrap: wrap;
    text-align: justify;
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.gray_hover};
    }
`;

const Desc = styled.p`
    font-size: 1.1rem;
    color: ${props => props.theme.desc_color};
    margin: 0px 0px 10px 22px;
    flex-wrap: wrap;
    max-width: 90%;
    text-align: justify;
`;

const CreateModalButton = styled.button`
    background-color: ${props => props.theme.dark_blue_hover};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px 35px;
    margin: 10px 0 20px 20px;
    width: calc(90% + 10px);
    font-size: 1.1rem;
    font-weight: 600;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.dark_blue};
    }

    &:active {
        background-color: ${props => props.theme.dark_blue_hover};
    }

    @media (max-width: 1300px) {
        margin-top: 5px;
    }
`

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
    padding: 5px 0px 0px 22px;
`;

const Input = styled.input`
    width: 90%;
    margin-left: 20px;
    margin-bottom: 10px;
    height: 20px;
    border: 1px solid ${props => props.theme.white_divider};
    outline: none;
    font-size: 1.4rem;
    padding: 10px 0 10px 5px;
    border-radius: 10px;
`;

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    width: clamp(0px, 80%, 600px);
    @media (max-width: 600px) {
        width: 90%;
        padding-right: 10px;
    }
    outline: none;
    box-shadow: none;
`;

const StyledDeleteIcon = styled(DeleteForeverIcon)`
    font-size: 25px;
    color: ${props => props.theme.red};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.red_hover};
    }
    &:active {
        color: ${props => props.theme.red};
    }
`;

const ButtonContainer = styled.td`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const Button = styled.button`
    background-color: ${props => props.theme.white_background};
    border: none;
    font-size: 1.1rem;
    font-weight: 400;
    margin-right: 5px;
    margin-left: 10px;
    padding: 0px;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;
    align-self: center;
    justify-self: center;
`;

const CreateButton = styled.button`
    background-color: ${props => props.theme.green};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    width: 200px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 600;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.green_hover};
    }

    &:active {
        background-color: ${props => props.theme.green};
    }
`

const TableContainer = styled.div`
    overflow-x: auto;
    min-height: 150px;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.white_background};
`;

const Text = styled.p`
    font-size: 1.1rem;
    font-weight: 350; 
    color: ${props => props.theme.dark_blue};
    font-type: regular;
    font-family: Plus Jakarta Sans, sans-serif;
    line-height: 1.7rem;

    @media (max-width: 768px) {
        padding-right: 15px;
    }
`

const Table = styled.table`
    max-width: 100%;

    th, td {
        text-align: left;
        padding-top: 15px;
        white-space: nowrap;
    }

    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    th:nth-child(2), td:nth-child(2) {
        padding-right: 30px;
        min-width: 190px;
    }

    th:not(:nth-child(2)), td:not(:nth-child(2)) {
        min-width: 130px;
        padding-right: 40px;
    }

    th:last-child, td:last-child {
        padding-right: 10px;
    }

    @media (max-width: 768px) {
        th:nth-child(1), td:nth-child(1) {
            min-width: 0px;
            padding-right: 30px;
        }
    
        th:not(:first-child), td:not(:first-child) {
            min-width: 0px;
        }
    
        th:last-child, td:last-child {
            min-width: 0px;
            padding-right: 20px;
        }
    }

    font-size: 1.1rem;
    font-weight: 400;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;
